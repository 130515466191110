import { AppBar, Container, Grid, Link, Toolbar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Branding } from '../../components/Branding';
import {
    getAuthenticatedBorrower,
    getAuthenticatedDataRoomUser,
} from '../../store/auth/AuthSelectors';
import { marketingUrl } from '../../utils/url';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    appbar: {
        borderBottom: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.primary.main,
    },
    toolbar: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
}));

interface HeaderProps {
    isBorrower?: boolean;
    isDataRoom?: boolean;
}

export const Header = (props: HeaderProps) => {
    const { isBorrower, isDataRoom } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    const borrower = useSelector(getAuthenticatedBorrower);
    const dataRoomUser = useSelector(getAuthenticatedDataRoomUser);

    return <AppBar color="default" position="relative" className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
            <Container maxWidth='md'>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                >
                    <Grid item>
                        <Branding color="primary" />
                    </Grid>
                    <Grid item>
                        { isBorrower && borrower &&
                            <Typography>
                                {t('Logged in as {{name}}', { name: borrower.firstName || borrower.companyName || 'Company' })}
                                {' '}
                                <Link href='/logout' color='primary'>{t('Logout')}</Link>
                            </Typography>
                        }

                        { isDataRoom && dataRoomUser &&
                        <Typography>
                            {t('Logged in as {{name}}', { name: dataRoomUser.firstName || dataRoomUser.companyName || 'Company' })}
                            {' '}
                            <Link href='/dataroom/logout' color='primary'>{t('Logout')}</Link>
                        </Typography>
                        }

                        { !isBorrower && !isDataRoom &&
                            <Typography>
                                <Link href={marketingUrl('/borrow')} color='primary'>{t('Back to homepage')}</Link>
                            </Typography>
                        }
                    </Grid>
                </Grid>
            </Container>
        </Toolbar>
    </AppBar>;
};
