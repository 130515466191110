import { Card, CardActions, CardContent, CardMedia, Chip, Grid, Typography } from "@mui/material";
import { CheckCircle, LocationCity, Warning } from "@mui/icons-material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";
import { SecondaryButton } from "../../../components/SecondaryButton";
import { SchedulePaymentStatus } from "../../../constants/SchedulePaymentStatus";
import { BorrowerLoanModel } from "../../../models/loan/BorrowerLoanModel";
import { formatPounds } from "../../../utils/money";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    icon: {
        fontSize: '6rem',
    },
    photo: {
        margin: theme.spacing(1),
        height: theme.spacing(10),
        width: theme.spacing(10),
    },
    actions: {
        paddingTop: 0,
        paddingBottom: theme.spacing(2),
    },
    viewAction: {
        margin: 'auto',
    },
}));

interface LoanWidgetProps {
    loan: BorrowerLoanModel;
}

export const LoanWidget = (props: LoanWidgetProps) => {
    const { loan } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    const isOverdue = !!loan.schedule.find(item => item.paymentStatus !== SchedulePaymentStatus.Paid && moment().diff(item.dueDate, 'days') > 0);
    const isPaid = loan.schedule.every(item => item.paymentStatus === SchedulePaymentStatus.Paid);

    const stateLabel = isOverdue && t('Overdue')
        || isPaid && t('Paid')
        || t('Up to date');

    return <Card>
        <CardContent>
            <Typography variant="h5" align="center" gutterBottom>
                <b>{loan.displayName}</b>
            </Typography>
            <Grid container>
                <Grid item xs justifyContent="center" alignItems="center" container direction="column">
                    <Chip avatar={isOverdue ?  <Warning /> : <CheckCircle />} label={stateLabel} />
                    {loan.photo
                        ? <CardMedia className={classes.photo} image={loan.photo} />
                        : <CardMedia component={LocationCity} className={classes.icon} />
                    }
                </Grid>
                <Grid item xs>
                    <Typography>
                        {t('Total Borrowed')}
                    </Typography>
                    <Typography gutterBottom>
                        <b>{formatPounds(loan.totalOutstandingLoanAmount || 0)}</b>
                    </Typography>
                    <Typography>
                        {t('Outstanding')}
                    </Typography>
                    <Typography>
                        <b>{formatPounds(loan.outstandingLoanAmount || 0)}</b>
                    </Typography>
                </Grid>
            </Grid>
        </CardContent>
        <CardActions className={classes.actions}>
            <SecondaryButton className={classes.viewAction} component={Link} to={`/loans/${loan.loanId || 0}`}>
                {t('View')}
            </SecondaryButton>
        </CardActions>
    </Card>;
};
