import { Box, FormHelperText, SvgIcon, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Person as PersonIcon, Business as BusinessIcon } from '@mui/icons-material';
import { RadioCards } from "../../../../components/RadioCards";
import { useFormikContext } from "formik";
import { AccountType } from "../../../../constants/AccountType";
import * as Yup from 'yup';
import { TFunction } from "i18next";

interface Values {
    accountType: string;
}

interface AccountTypeItem {
    value: AccountType;
    label: string;
    icon: typeof SvgIcon;
}

export const accountTypeValidationSchema = (t: TFunction) => Yup.object().shape({
    accountType: Yup.string().oneOf(['borrower', 'broker']).required(t('Please select account type')),
});

export const accountTypeInitialValues = {
    accountType: '',
};

export const AccountTypeStep = () => {
    const { t } = useTranslation();

    const formik = useFormikContext<Values>();

    const accountTypes: AccountTypeItem[] = [
        {
            value: AccountType.Borrower,
            label: t('Borrower'),
            icon: PersonIcon,
        },
        {
            value: AccountType.Broker,
            label: t('Broker'),
            icon: BusinessIcon,
        },
    ];

    return <Box>
        <Typography gutterBottom variant="h5">
            <b>{t('Are you a borrower or a broker?')}</b>
        </Typography>
        <RadioCards
            selectedOption={formik.values.accountType}
            options={accountTypes}
            onOptionChange={(value) => formik.setFieldValue('accountType', value)}
        />
        <FormHelperText error={formik.touched.accountType && Boolean(formik.errors.accountType)}>
            {formik.touched.accountType && formik.errors.accountType}
        </FormHelperText>
    </Box>;
};
