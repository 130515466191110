import { List, ListItemButton, ListItemIcon, ListItemText, SvgIcon } from "@mui/material";
import { Link, useLocation } from "react-router";

export interface NavigationItem {
    icon: typeof SvgIcon;
    label: string;
    path: string;
}

export interface NavigationProps {
    navigation: NavigationItem[];
}

export const Navigation = (props: NavigationProps) => {
    const { navigation } = props;
    const location = useLocation();

    return <List>
        {navigation.map(
            ({ path, icon: Icon, label }, index) =>
                <ListItemButton key={index} component={Link} to={path} selected={location.pathname.startsWith(path)}>
                    <ListItemIcon>
                        <Icon />
                    </ListItemIcon>
                    <ListItemText>{label}</ListItemText>
                </ListItemButton>,
        )}
    </List>;
};
