import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { Navigation, NavigationItem } from "../../Common/Navigation";
import { Dashboard as DashboardIcon, ListAlt as ListAltIcon } from "@mui/icons-material";

const menu = (t: TFunction): NavigationItem[] => [
    {
        label: t('Statistics'),
        path: '/statistics',
        icon: DashboardIcon,
    },
    {
        label: t('Data Room'),
        path: '/dataroom/loans',
        icon: ListAltIcon,
    },

];

export const Sidebar = () => {
    const { t } = useTranslation();
    return <Navigation navigation={menu(t)} />;
};
