import { Box, Grid, Typography } from "@mui/material";
import { Pagination } from "@mui/lab";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../components/Loader";
import { LoanActions } from "../../../store/loan/LoanActions";
import { getActiveBorrowerLoansCount, getActiveBorrowerLoansLoading, getActiveBorrowerLoansPaginated } from "../../../store/loan/LoanSelectors";
import { LoanWidget } from "./LoanWidget";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    pagination: {
        marginTop: theme.spacing(2),
    },
}));

const PAGE_SIZE = 6;

export const CurrentLoans = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    const [page, setPage] = useState(1);

    const currentLoans = useSelector(getActiveBorrowerLoansPaginated(page));
    const currentLoansCount = useSelector(getActiveBorrowerLoansCount);
    const currentLoansLoading = useSelector(getActiveBorrowerLoansLoading);
    const pagesCount = Math.ceil(currentLoansCount / PAGE_SIZE) || 1;

    useEffect(() => {
        dispatch(LoanActions.getActiveBorrowerLoans(page, PAGE_SIZE));
    }, [dispatch, page]);

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return <Box className={classes.root}>
        <Typography variant="h5" gutterBottom>{t('Current Loans')}</Typography>
        { currentLoansLoading
            ? <Loader wide centered />
            : currentLoansCount > 0
                ? (
                    <Fragment>
                        <Grid container spacing={2}>
                            {currentLoans.map((loan, index) => (
                                <Grid item xs={12} sm={6} key={index}>
                                    <LoanWidget loan={loan} />
                                </Grid>
                            ))}
                        </Grid>
                        { pagesCount > 1 &&
                            <Grid container justifyContent="center" className={classes.pagination}>
                                <Pagination
                                    count={pagesCount}
                                    page={page}
                                    onChange={handlePageChange}
                                    disabled={currentLoansLoading}
                                />
                            </Grid>
                        }
                    </Fragment>
                ) : (
                    <Typography>{t('No records found')}</Typography>
                )
        }
    </Box>;
};
