import {
    TableHead,
    TableRow,
    TableCell,
} from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    labelCell: {
        cursor: 'pointer',
    },
    directionSignSell:{
        display: 'inline-block',
        width: 20,
    },
    directionSign:{
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '1rem',
        position: 'relative',
        top: 3,
        left: 2,
    },
}));

type KeyValue = number|string|symbol;

export type ColumnDefinitionType<T extends KeyValue> = {
    key: T;
    header: string;
    sorting?: boolean;
    width?: number;
};

export interface SortConfig<T> {
    propertyName: T;
    sortType: SortingType;
}

export enum SortingType {
    Ascending = 'asc',
    Descending = 'desc',
}

interface SortableHeaderProps<T extends KeyValue> {
    columns: ColumnDefinitionType<T>[];
    sortConfig: SortConfig<T>;
    sortBy: (string: T) => void;
}

export const SortableHeader = <T extends KeyValue> (props: SortableHeaderProps<T>) => {
    const { columns, sortConfig, sortBy } = props;

    const classes = useStyles();

    const getSortDirection = (property: T) => {
        const config = sortConfig.propertyName === property;
        if (config) {
            if (sortConfig.sortType === SortingType.Descending) {
                return <ArrowDownward className={classes.directionSign} />;
            } else {
                return <ArrowUpward className={classes.directionSign} />;
            }
        }
        return null;
    };

    return (
        <TableHead>
            <TableRow>
                {columns.map((column, index) =>
                    column.sorting ?
                        (
                            <TableCell
                                key={`headCell-${index}`}
                            >
                                <span
                                    onClick={() => sortBy(column.key)}
                                    className={classes.labelCell}
                                >
                                    {column.header}
                                    <span className={classes.directionSignSell}>
                                        {getSortDirection(column.key)}
                                    </span>
                                </span>

                            </TableCell>
                        ) :
                        (
                            <TableCell
                                key={`headCell-${index}`}
                            >
                                <span>
                                    {column.header}
                                    {getSortDirection(column.key)}
                                </span>
                            </TableCell>
                        ))
                }
            </TableRow>
        </TableHead>
    );
};
