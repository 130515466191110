import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AuthActions } from "../../store/auth/AuthActions";
import { useNavigate } from "react-router";

export const Logout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(AuthActions.dataRoomLogout(navigate));
    }, [dispatch, navigate]);

    return null;
};
