import { BrowserRouter, Navigate, Route, Routes } from 'react-router';
import { Login as BorrowerLogin } from './pages/Borrower/Login';
import { Login as DataRoomLogin } from './pages/DataRoom/Login';
import { Register } from './pages/Borrower/Register';
import { RegisterDone } from './pages/Borrower/RegisterDone';
import { Dashboard } from "./pages/Borrower/Dashboard";
import { DataRoomLoans } from './pages/DataRoom/DataRoomLoans';
import { DataRoomLoan } from './pages/DataRoom/DataRoomLoan';
import { MyLoans } from './pages/Borrower/MyLoans';
import { ApplyForLoan } from './pages/Borrower/ApplyForLoan';
import { ApplyForLoanDone } from './pages/Borrower/ApplyForLoanDone';
import { Loan } from './pages/Borrower/Loan';
import { ScrollToTop } from './components/ScrollToTop';
import { Logout as BorrowerLogout } from './pages/Borrower/Logout';
import { Logout as DataRoomLogout } from './pages/DataRoom/Logout';
import { NotFound } from './pages/NotFound';
import { Layout as BorrowerLayout } from './containers/Borrower/Common/Layout';
import { Layout as DataRoomLayout } from './containers/DataRoom/Common/Layout';
import { Statistics } from './pages/DataRoom/Statistics';
import { useSelector } from 'react-redux';
import { getAuthenticatedBorrower, getAuthenticatedDataRoomUser } from './store/auth/AuthSelectors';
import { addSearchParameters } from './utils/url';

export const Router = () => {
    const borrower = useSelector(getAuthenticatedBorrower);
    const dataRoomUser = useSelector(getAuthenticatedDataRoomUser);

    const getBorrowerElement = (element: JSX.Element) => {
        if (!borrower) {
            return <Navigate
                to={{
                    pathname: '/login',
                    search: addSearchParameters(location.search, { path: location.pathname }),
                }}
            />;
        }

        return <BorrowerLayout>{element}</BorrowerLayout>;
    };

    const getDataRoomElement = (element: JSX.Element) => {
        if (!dataRoomUser) {
            return <Navigate
                to={{
                    pathname: '/dataroom/login',
                    search: addSearchParameters(location.search, { path: location.pathname }),
                }}
            />;
        }

        return <DataRoomLayout>{element}</DataRoomLayout>;
    };

    return <BrowserRouter>
        <ScrollToTop />
        <Routes>
            <Route path="/" element={<Navigate to="/register" />} />
            <Route path="/login" element={<BorrowerLogin />} />
            <Route path="/dataroom/login" element={<DataRoomLogin />} />
            <Route path="/register" element={<Register />} />
            <Route path="/register/done" element={<RegisterDone />} />

            <Route path="/dashboard" element={getBorrowerElement(<Dashboard />)} />
            <Route path="/loans" element={getBorrowerElement(<MyLoans />)} />
            <Route path="/loans/:id" element={getBorrowerElement(<Loan />)} />
            <Route path="/apply-for-loan" element={getBorrowerElement(<ApplyForLoan />)} />
            <Route path="/apply-for-loan/done" element={getBorrowerElement(<ApplyForLoanDone />)} />
            <Route path="/logout" element={getBorrowerElement(<BorrowerLogout />)} />

            <Route path="/statistics" element={getDataRoomElement(<Statistics />)} />
            <Route path="/dataroom/loans/:id" element={getDataRoomElement(<DataRoomLoan />)} />
            <Route path="/dataroom/loans" element={getDataRoomElement(<DataRoomLoans />)} />
            <Route path="/dataroom/logout" element={getDataRoomElement(<DataRoomLogout />)} />

            <Route path="*" element={<NotFound />} />
        </Routes>
    </BrowserRouter>;
};
