import { Box, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BorrowerLoanModel } from "../../../models/loan/BorrowerLoanModel";

interface DocumentsTabProps {
    loan: BorrowerLoanModel;
}

export const DocumentsTab = (props: DocumentsTabProps) => {
    const { t } = useTranslation();
    const { loan } = props;

    return <Box>
        <Typography variant="h5" gutterBottom>{t('Loan Documents')}</Typography>
        {loan.documents.length > 0
            ? (
                loan.documents.map((item, index) => (
                    <Typography key={index} gutterBottom>
                        <Link href={item.file} target="__blank">{item.name}</Link> {t('uploaded')} <b>{item.timestamp.format('Do MMMM YYYY')}</b>
                    </Typography>
                ))
            ) : (
                <Typography>{t('No documents found')}</Typography>
            )
        }
    </Box>;
};
