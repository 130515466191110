import { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBorrowerAuthenticationFinished, getDataRoomAuthenticationFinished } from "../store/auth/AuthSelectors";
import { AuthActions } from "../store/auth/AuthActions";
import { Backdrop, CircularProgress } from "@mui/material";

interface AuthenticationProviderProps {
    children: ReactNode;
}

export const AuthenticationProvider = ({ children }: AuthenticationProviderProps) => {
    const dispatch = useDispatch();

    const borrowerAuthenticationFinished = useSelector(getBorrowerAuthenticationFinished);
    const dataRoomAuthenticationFinished = useSelector(getDataRoomAuthenticationFinished);

    useEffect(() => {
        dispatch(AuthActions.authenticateBorrower());
        dispatch(AuthActions.authenticateDataRoomUser());
    }, [dispatch]);

    return borrowerAuthenticationFinished && dataRoomAuthenticationFinished
        ? <>{children}</>
        : <Backdrop open>
            <CircularProgress />
        </Backdrop>;
};
