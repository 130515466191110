import { NavigateFunction } from 'react-router';
import { BorrowerModel } from '../../models/auth/BorrowerModel';
import { DataRoomUserModel } from '../../models/auth/DataRoomUserModel';
import { ActionsUnion, createAction } from '../../utils/store';

export const BORROWER_LOGIN = 'BORROWER_LOGIN';
export const BORROWER_LOGIN_SUCCESS = 'BORROWER_LOGIN_SUCCESS';
export const BORROWER_LOGIN_FAILED = 'BORROWER_LOGIN_FAILED';

export const BORROWER_LOGOUT = 'BORROWER_LOGOUT';
export const BORROWER_LOGOUT_SUCCESS = 'BORROWER_LOGOUT_SUCCESS';
export const BORROWER_LOGOUT_FAILED = 'BORROWER_LOGOUT_FAILED';

export const AUTHENTICATE_BORROWER = 'AUTHENTICATE_BORROWER';
export const AUTHENTICATE_BORROWER_SUCCESS = 'AUTHENTICATE_BORROWER_SUCCESS';
export const AUTHENTICATE_BORROWER_FAILED = 'AUTHENTICATE_BORROWER_FAILED';

export const DATA_ROOM_LOGIN = 'DATA_ROOM_LOGIN';
export const DATA_ROOM_LOGIN_SUCCESS = 'DATA_ROOM_LOGIN_SUCCESS';
export const DATA_ROOM_LOGIN_FAILED = 'DATA_ROOM_LOGIN_FAILED';

export const DATA_ROOM_LOGOUT = 'DATA_ROOM_LOGOUT';
export const DATA_ROOM_LOGOUT_SUCCESS = 'DATA_ROOM_LOGOUT_SUCCESS';
export const DATA_ROOM_LOGOUT_FAILED = 'DATA_ROOM_LOGOUT_FAILED';

export const AUTHENTICATE_DATA_ROOM_USER = 'AUTHENTICATE_DATA_ROOM_USER';
export const AUTHENTICATE_DATA_ROOM_USER_SUCCESS = 'AUTHENTICATE_DATA_ROOM_USER_SUCCESS';
export const AUTHENTICATE_DATA_ROOM_USER_FAILED = 'AUTHENTICATE_DATA_ROOM_USER_FAILED';

export const AuthActions = {
    borrowerLogin: (token: string, refreshToken: string, logoutRedirectUrl: string | null) => createAction(BORROWER_LOGIN, { token, refreshToken, logoutRedirectUrl }),
    borrowerLoginSuccess: (borrower: BorrowerModel) => createAction(BORROWER_LOGIN_SUCCESS, { borrower }),
    borrowerLoginFailed: () => createAction(BORROWER_LOGIN_FAILED),

    borrowerLogout: (navigate: NavigateFunction) => createAction(BORROWER_LOGOUT, { navigate }),
    borrowerLogoutSuccess: (navigate: NavigateFunction) => createAction(BORROWER_LOGOUT_SUCCESS, { navigate }),
    borrowerLogoutFailed: () => createAction(BORROWER_LOGOUT_FAILED),

    authenticateBorrower: () => createAction(AUTHENTICATE_BORROWER),
    authenticateBorrowerSuccess: (borrower: BorrowerModel) => createAction(AUTHENTICATE_BORROWER_SUCCESS, { borrower }),
    authenticateBorrowerFailed: () => createAction(AUTHENTICATE_BORROWER_FAILED),

    dataRoomLogin: (token: string, refreshToken: string) => createAction(DATA_ROOM_LOGIN, { token, refreshToken }),
    dataRoomLoginSuccess: (user: DataRoomUserModel) => createAction(DATA_ROOM_LOGIN_SUCCESS, { user }),
    dataRoomLoginFailed: () => createAction(DATA_ROOM_LOGIN_FAILED),

    dataRoomLogout: (navigate: NavigateFunction) => createAction(DATA_ROOM_LOGOUT, { navigate }),
    dataRoomLogoutSuccess: (navigate: NavigateFunction) => createAction(DATA_ROOM_LOGOUT_SUCCESS, { navigate }),
    dataRoomLogoutFailed: () => createAction(DATA_ROOM_LOGOUT_FAILED),

    authenticateDataRoomUser: () => createAction(AUTHENTICATE_DATA_ROOM_USER),
    authenticateDataRoomUserSuccess: (user: DataRoomUserModel) => createAction(AUTHENTICATE_DATA_ROOM_USER_SUCCESS, { user }),
    authenticateDataRoomUserFailed: () => createAction(AUTHENTICATE_DATA_ROOM_USER_FAILED),
};

export type AuthActionsType = ActionsUnion<typeof AuthActions>;
