import { Box, Collapse, Grid, Typography } from "@mui/material";
import { Pagination } from "@mui/lab";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../components/Loader";
import { LoanActions } from "../../../store/loan/LoanActions";
import { getPendingBorrowerLoansCount, getPendingBorrowerLoansLoading, getPendingBorrowerLoansPaginated } from "../../../store/loan/LoanSelectors";
import { PendingLoanWidget } from "./PendingLoanWidget";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    pagination: {
        marginTop: theme.spacing(2),
    },
}));

const PAGE_SIZE = 6;

export const PendingLoans = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    const [page, setPage] = useState(1);

    const pendingLoans = useSelector(getPendingBorrowerLoansPaginated(page));
    const pendingLoansCount = useSelector(getPendingBorrowerLoansCount);
    const pendingLoansLoading = useSelector(getPendingBorrowerLoansLoading);
    const pagesCount = Math.ceil(pendingLoansCount / PAGE_SIZE) || 1;

    useEffect(() => {
        dispatch(LoanActions.getPendingBorrowerLoans(page, PAGE_SIZE));
    }, [dispatch, page]);

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return <Collapse in={pendingLoansCount > 0} exit={false}>
        <Box className={classes.root}>
            <Typography variant="h5" gutterBottom>{t('Pending Loans')}</Typography>
            { pendingLoansLoading
                ? <Loader wide centered />
                : pendingLoansCount > 0
                    ? (
                        <Fragment>
                            <Grid container spacing={2}>
                                {pendingLoans.map((loan, index) => (
                                    <Grid item xs={12} sm={6} key={index}>
                                        <PendingLoanWidget loan={loan} />
                                    </Grid>
                                ))}
                            </Grid>
                            { pagesCount > 1 &&
                                <Grid container justifyContent="center" className={classes.pagination}>
                                    <Pagination
                                        count={pagesCount}
                                        page={page}
                                        onChange={handlePageChange}
                                        disabled={pendingLoansLoading}
                                    />
                                </Grid>
                            }
                        </Fragment>
                    ) : (
                        <Typography>{t('No records found')}</Typography>
                    )
            }
        </Box>
    </Collapse>;
};

