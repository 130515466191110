import { Box, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    wide: {
        padding: theme.spacing(8),
    },
    centered: {
        display: "flex",
        justifyContent: "center",
    },
}));

interface LoaderProps {
    wide?: boolean;
    centered?: boolean;
}

export const Loader = (props: LoaderProps) => {
    const { wide, centered } = props;
    const classes = useStyles();

    return <Box
        className={clsx({
            [classes.wide]: wide,
            [classes.centered]: centered,
        })}
    >
        <CircularProgress />
    </Box>;
};
