import { TextField, TextFieldProps } from "@mui/material";
import { ChangeEvent } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";

type NumberInputProps = Omit<NumericFormatProps<TextFieldProps>, "customInput"|"onValueChange">;

export const NumberInput = ({ onChange, ...other }: NumberInputProps) => {
    return <NumericFormat
        {...other}
        onValueChange={(values) =>
            onChange?.({
                target: {
                    name: other.name,
                    value: values.value,
                },
            } as ChangeEvent<HTMLInputElement>)
        }
        customInput={TextField}
    />;
};
