import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from 'react';
import { LoanActions } from '../../../store/loan/LoanActions';
import {
    getDataRoomLoanById,
    getDataRoomLoanLoading,
} from '../../../store/loan/LoanSelectors';
import useScript from '../../../hooks/useScript';
import { dropboxScriptConfig, dropboxSrc } from '../../../constants/Dropbox';
import {
    Container,
    Divider,
    Grid,
    Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatPounds } from '../../../utils/money';
import { capitalizeFirstLetter } from '../../../utils/string';
import moment from 'moment';
import * as React from 'react';
import { Loader } from '../../../components/Loader';
import { DropboxWidget } from './DropboxWidget';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    container: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    divider:{
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        maxWidth: '320px',
        margin: 'auto',
    },
    loanName:{
        fontWeight:'bold',
    },
    keyInfo:{
        fontWeight:'bold',
        marginTop: '43px',
    },
    loanOption:{
        lineHeight:1.9,
    },
}));

export const LoanContainer = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();

    useScript(dropboxSrc, dropboxScriptConfig);

    const loan = useSelector(getDataRoomLoanById(Number(id)));
    const dataRoomLoanLoading = useSelector(getDataRoomLoanLoading);

    useEffect(() => {
        if (!loan) {
            dispatch(LoanActions.getDataRoomLoan(Number(id)));
        }
    }, [dispatch, loan, id]);

    return dataRoomLoanLoading || !loan ? <Loader wide centered /> :
        <Container maxWidth="md" className={classes.container}>
            <Typography gutterBottom variant="h4" align="center" className={classes.loanName}>
                {capitalizeFirstLetter(loan.displayName)} - {loan.loanCode}
            </Typography>
            <Divider  className={classes.divider} orientation={'horizontal'} variant={'middle'} />

            <Typography gutterBottom variant="h5" align="left" className={classes.keyInfo}>
                {t('Key Information').toUpperCase()}
            </Typography>

            <Grid container direction="row" spacing={2}
                className={classes.root}
            >
                <Grid item container justifyContent={'flex-start'} spacing={2} md={6}
                    xs={12}
                >
                    <Grid item md={6} xs={12}>
                        <Typography variant="h6" align="left"
                            className={classes.loanOption}
                        >
                            {t('Loan Amount')}:
                        </Typography>
                        <Typography variant="h6" align="left"
                            className={classes.loanOption}
                        >
                            {t('Asset Value Amount')}:
                        </Typography>
                        <Typography variant="h6" align="left"
                            className={classes.loanOption}
                        >
                            {t('LTGDV')}:
                        </Typography>
                        <Typography variant="h6" align="left"
                            className={classes.loanOption}
                        >
                            {t('Primary Security')}:
                        </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Typography variant="h6" align="left"
                            className={classes.loanOption}
                        >
                            {formatPounds(loan.loanAmount)}
                        </Typography>
                        <Typography variant="h6" align="left"
                            className={classes.loanOption}
                        >
                            {formatPounds(loan.assetValue)}
                        </Typography>
                        <Typography variant="h6" align="left"
                            className={classes.loanOption}
                        >
                            {loan.totalLTV}%
                        </Typography>
                        <Typography variant="h6" align="left"
                            className={classes.loanOption}
                        >
                            {loan.primarySecurity}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container justifyContent={'flex-start'} spacing={2} md={6}
                    xs={12}
                >
                    <Grid item md={6} xs={12}>
                        <Typography variant="h6" align="left"
                            className={classes.loanOption}
                        >
                            {t('Loan Type')}:
                        </Typography>
                        <Typography variant="h6" align="left"
                            className={classes.loanOption}
                        >
                            {t('Risk Grade')}:
                        </Typography>
                        <Typography variant="h6" align="left"
                            className={classes.loanOption}
                        >
                            {t('Loan Term')}:
                        </Typography>
                        <Typography variant="h6" align="left"
                            className={classes.loanOption}
                        >
                            {t('Start Date')}:
                        </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Typography variant="h6" align="left"
                            className={classes.loanOption}
                        >
                            {capitalizeFirstLetter(loan.loanType)}
                        </Typography>
                        <Typography variant="h6" align="left"
                            className={classes.loanOption}
                        >
                            {loan.riskRating}
                        </Typography>
                        <Typography variant="h6" align="left"
                            className={classes.loanOption}
                        >
                            {loan.loanTerm} Months
                        </Typography>
                        <Typography variant="h6" align="left"
                            className={classes.loanOption}
                        >
                            {moment(loan.startDate).format('DD/MM/YYYY')}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Typography gutterBottom variant="h5" align="left" className={classes.keyInfo}>
                {t('Files').toUpperCase()}
            </Typography>

            <DropboxWidget loanCode={loan.loanCode} />

        </Container>;
};
