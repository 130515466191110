import { Card, CardContent, Grid, Skeleton, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LoanActions } from "../../../store/loan/LoanActions";
import { getStatistics, getStatisticsLoading } from "../../../store/loan/LoanSelectors";
import { formatPounds } from "../../../utils/money";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}));

export const DashboardContainer = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();

    const statistics = useSelector(getStatistics);
    const statisticsLoaning = useSelector(getStatisticsLoading);

    useEffect(() => {
        dispatch(LoanActions.getStatistics());
    }, [dispatch]);

    const outstandingAmount = formatPounds(statistics?.outstandingAmount || 0);

    return <Grid container direction="column" spacing={2} className={classes.root}>
        <Grid item container justifyContent="center" spacing={2}>
            <Grid item md={4} xs={12}>
                <Card>
                    <CardContent>
                        <Typography variant="h6" component="h2" align="center">
                            {t('Amount Outstanding')}
                        </Typography>
                        <Typography variant="h5" align="center">
                            <b>{statisticsLoaning ? <Skeleton /> : outstandingAmount}</b>
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        <Grid item container justifyContent="center" spacing={2}>
            <Grid item md={4} xs={12}>
                <Card>
                    <CardContent>
                        <Typography variant="h6" component="h2" align="center">
                            {t('Awaiting Decision')}
                        </Typography>
                        <Typography variant="h3" align="center">
                            {statisticsLoaning ? <Skeleton /> : statistics?.pendingLoanApplicationsCount}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item md={4} xs={12}>
                <Card>
                    <CardContent>
                        <Typography variant="h6" component="h2" align="center">
                            {t('Active Loans')}
                        </Typography>
                        <Typography variant="h3" align="center">
                            {statisticsLoaning ? <Skeleton /> : statistics?.activeLoansCount}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item md={4} xs={12}>
                <Card>
                    <CardContent>
                        <Typography variant="h6" component="h2" align="center">
                            {t('Completed Loans')}
                        </Typography>
                        <Typography variant="h3" align="center">
                            {statisticsLoaning ? <Skeleton /> : statistics?.completedLoansCount}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </Grid>;
};
