import { Typography, TypographyProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
    root: {
        fontFamily: 'Cooper Black',
        cursor: 'pointer',
    },
});

export const Branding = (props: TypographyProps) => {
    const { className, ...other } = props;
    const classes = useStyles();

    return <Typography className={clsx(classes.root, className)} variant='h4' {...other}>
        easyMoney
    </Typography>;
};
