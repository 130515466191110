import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { Navigation, NavigationItem } from "../../Common/Navigation";
import { Assignment as AssignmentIcon, Dashboard as DashboardIcon, ListAlt as ListAltIcon } from "@mui/icons-material";

const menu = (t: TFunction): NavigationItem[] => [
    {
        label: t('Dashboard'),
        path: '/dashboard',
        icon: DashboardIcon,
    },
    {
        label: t('My Loans'),
        path: '/loans',
        icon: ListAltIcon,
    },
    {
        label: t('Apply for Loan'),
        path: '/apply-for-loan',
        icon: AssignmentIcon,
    },
];

export const Sidebar = () => {
    const { t } = useTranslation();
    return <Navigation navigation={menu(t)} />;
};
