import { Container, Grid } from "@mui/material";
import { Fragment, ReactNode } from "react";
import { Sidebar } from "./Sidebar";
import { Header } from "../../Common/Header";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

export const Layout = ({ children }: { children: ReactNode }) => {
    const classes = useStyles();

    return <Fragment>
        <Header isBorrower />
        <Container maxWidth="md" className={classes.container}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3} sm={4}>
                    <Sidebar />
                </Grid>
                <Grid item xs={12} md={9} sm={8}>
                    {children}
                </Grid>
            </Grid>
        </Container>
    </Fragment>;
};
