import { Box, Container, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../../../components/PrimaryButton";
import { SecondaryButton } from "../../../components/SecondaryButton";
import { accountTypeInitialValues, AccountTypeStep, accountTypeValidationSchema } from "../Common/LoanApplicationForm/AccountTypeStep";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { loanDetailsInitialValues, LoanDetailsStep, loanDetailsValidationSchema } from "../Common/LoanApplicationForm/LoanDetailsStep";
import { useDispatch, useSelector } from "react-redux";
import { TFunction } from "i18next";
import { getCreateLoanApplicationInProcess } from "../../../store/loanApplication/LoanApplicationSelectors";
import { propertiesInitialValues, PropertiesStep, propertiesValidationSchema } from "../Common/LoanApplicationForm/PropertiesStep";
import { LoanApplicationActions } from "../../../store/loanApplication/LoanApplicationActions";
import { usePrevious } from "../../../hooks/usePrevious";
import { LoanType } from "../../../constants/LoanType";
import { useNavigate } from "react-router";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    stepper: {
        padding: 0,
        marginTop: theme.spacing(4),
        background: 'transparent',
    },
    content: {
        marginTop: theme.spacing(4),
    },
    actions: {
        marginTop: theme.spacing(4),
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(2),
        },
    },
}));

const getSteps = (t: TFunction) => [
    {
        label: 'Start',
        validationSchema: accountTypeValidationSchema(t),
    },
    {
        label: '',
        validationSchema: propertiesValidationSchema(t),
    },
    {
        label: 'Finish',
        validationSchema: loanDetailsValidationSchema(t),
    },
];

const initialValues = {
    // Step 1
    ...accountTypeInitialValues,

    // Step 2
    ...propertiesInitialValues,

    // Step 3
    ...loanDetailsInitialValues,
};

export const ApplyForLoanContainer = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const formikRef = useRef<FormikProps<typeof initialValues>>(null);

    const [activeStep, setActiveStep] = useState(0);
    const [snapshot, setSnapshot] = useState(initialValues);

    const steps = useMemo(() => getSteps(t), [t]);
    const stepData = steps[activeStep];
    const isLastStep = activeStep === steps.length - 1;
    const isFirstStep = activeStep === 0;

    const createLoanApplicationInProcess = useSelector(getCreateLoanApplicationInProcess);
    const previousCreateLoanApplicationInProcess = usePrevious(createLoanApplicationInProcess);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [activeStep]);

    useEffect(() => {
        if (previousCreateLoanApplicationInProcess === true && createLoanApplicationInProcess === false) {
            formikRef.current?.setSubmitting(false);
            void navigate('/apply-for-loan/done');
        }
    }, [navigate, createLoanApplicationInProcess, previousCreateLoanApplicationInProcess]);

    const handleNext = (values: typeof initialValues) => {
        setSnapshot(values);
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = (values: typeof initialValues) => {
        setSnapshot(values);
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleSubmit = (values: typeof initialValues, actions: FormikHelpers<typeof initialValues>) => {
        if (isLastStep) {
            dispatch(LoanApplicationActions.createLoanApplication({
                loanType: values.loanType,
                loanPurpose: values.loanPurpose,
                loanPurposeOther: values.loanPurposeOther,
                landLoanAmount: values.landLoanAmount ? Number(values.landLoanAmount) : undefined,
                developmentLoanAmount: values.developmentLoanAmount ? Number(values.developmentLoanAmount) : undefined,
                buildCosts: values.buildCosts ? Number(values.buildCosts) : undefined,
                otherCosts: values.otherCosts ? Number(values.otherCosts) : undefined,
                developmentDescription: values.developmentDescription,
                additionalInformation: values.additionalInformation,
                isDiscussed: values.isDiscussed === 'true',
                loanAmount: values.loanAmount ? Number(values.loanAmount) : undefined,
                loanTerm: Number(values.loanTerm),
                assetValue: values.loanType === LoanType.Development
                    ? Number(values.assetValue)
                    : values.properties.reduce((acc, item) => acc + Number(item.value), 0),
                exitStrategy: values.exitStrategy,
                applicantType: values.accountType,
                properties: values.properties.map(item => ({
                    isFirstCharge: item.isFirstCharge === 'true',
                    otherChargesValue: item.otherCharges ? Number(item.otherCharges) : undefined,
                    addressPostalCode: item.addressPostalCode,
                    addressLineOne: item.addressLineOne,
                    addressLineTwo: item.addressLineTwo,
                    addressCity: item.addressCity,
                    addressRegion: item.addressRegion,
                    addressCountry: item.addressCountry,
                    type: item.propertyType,
                    typeOther: item.propertyTypeOther,
                    isVacant: item.isVacant === 'true',
                    description: item.description,
                    assetValue: Number(item.value),
                })),
            }));
        } else {
            void actions.setTouched({});
            handleNext(values);
            actions.setSubmitting(false);
        }
    };

    const getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return <AccountTypeStep />;
            case 1:
                return <PropertiesStep />;
            case 2:
                return <LoanDetailsStep />;
        }
    };

    return <Container maxWidth="md" className={classes.container}>
        <Typography gutterBottom variant="h4" align="center">
            {t('Apply for a bridge or development loan')}
        </Typography>

        <Stepper alternativeLabel activeStep={activeStep} className={classes.stepper}>
            {steps.map(
                (value, index) => <Step key={index}>
                    <StepLabel>
                        <Typography gutterBottom variant="h6">{value.label}</Typography>
                    </StepLabel>
                </Step>,
            )}
        </Stepper>

        <Formik
            innerRef={formikRef}
            initialValues={snapshot}
            validationSchema={stepData.validationSchema}
            onSubmit={handleSubmit}
            validateOnChange={false}
            validateOnBlur={true}
        >
            {formik => (
                <Form>
                    <Box className={classes.content}>
                        {getStepContent(activeStep)}
                    </Box>

                    <Box className={classes.actions}>
                        <PrimaryButton size="large" disabled={formik.isSubmitting} type="submit">
                            { isLastStep ? t('Submit') : t('Continue') }
                        </PrimaryButton>
                        { !isFirstStep &&
                            <SecondaryButton size="large" onClick={() => handleBack(formik.values)}>
                                {t('Go back a step')}
                            </SecondaryButton>
                        }
                    </Box>
                </Form>
            )}
        </Formik>
    </Container>;
};
