import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemButton,
} from "@mui/material";
import { SecondaryButton } from "../../components/SecondaryButton";
import { FindAddressModel } from "../../models/system/FindAddressModel";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    loader: {
        display: 'flex',
        margin: 'auto',
    },
});

interface FindAddressDialogProps {
    isOpen: boolean;
    isLoading: boolean;
    addresses: FindAddressModel[];
    onSelect: (selectedAddress: FindAddressModel) => void;
    onClose: () => void;
}

export const FindAddressDialog = (props: FindAddressDialogProps) => {
    const { isOpen, isLoading, addresses, onSelect, onClose } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    const addressStrings = addresses.map(address => {
        let addressString = address.lineOne;

        if (address.lineTwo) {
            addressString = addressString + ', ' + address.lineTwo;
        }

        if (address.lineThree) {
            addressString = addressString + ', ' + address.lineThree;
        }

        if (address.lineFour) {
            addressString = addressString + ', ' + address.lineFour;
        }

        if (address.city) {
            addressString = addressString + ', ' + address.city;
        }

        if (address.region) {
            addressString = addressString + ', ' + address.region;
        }

        return addressString;
    });

    return <Dialog open={isOpen}>
        <DialogTitle>Select address</DialogTitle>
        <DialogContent>
            <List>
                {isLoading ? <CircularProgress className={classes.loader} /> : (
                    addressStrings.length ? addressStrings.map((address, index) => (
                        <ListItemButton key={index} onClick={() => onSelect(addresses[index])}>
                            {address}
                        </ListItemButton>
                    )) : (
                        <ListItem>
                            {t('No addresses found')}
                        </ListItem>
                    )
                )}
            </List>
        </DialogContent>
        <DialogActions>
            <SecondaryButton onClick={onClose}>Close</SecondaryButton>
        </DialogActions>
    </Dialog>;
};
