import { Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";
import { PrimaryButton } from "../../../components/PrimaryButton";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    action: {
        marginTop: theme.spacing(2),
    },
}));

export const LoanApplicationSubmitted = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    return <Container maxWidth="md" className={classes.container}>
        <Typography gutterBottom variant="h3">
            <b>{t('All done!')}</b>
        </Typography>
        <Typography gutterBottom variant="h6">
            {t('You have received your application and will revert back shortly.')}
        </Typography>
        <Typography gutterBottom variant="h6">
            {t('You can check the status of your application at any time by logging in.')}
        </Typography>

        <PrimaryButton size="large" className={classes.action} component={Link} to="/dashboard">
            {t('Go to dashboard')}
        </PrimaryButton>
    </Container>;
};
