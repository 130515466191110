import './App.css';
import { Provider } from 'react-redux';
import { createAppStore } from './store';
import { Router } from './Router';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import { initializeI18n } from './localizations';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AuthenticationProvider } from "./components/AuthenticationProvider";
import * as Yup from 'yup';
import { sequenceSchema } from './utils/validation';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const store = createAppStore();

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    components: {
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
            },
        },
        MuiFormControl: {
            defaultProps: {
                variant: 'standard',
            },
        },
        MuiSelect: {
            defaultProps: {
                variant: 'standard',
            },
        },
    },
    palette: {
        primary: {
            main: '#ec6333',
        },
    },
    typography: {
        fontFamily: 'Futura Md',
    },
});

initializeI18n();
Yup.addMethod(Yup.StringSchema, 'sequence', sequenceSchema);

function App() {
    return (
        <Provider store={store}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <AuthenticationProvider>
                            <CssBaseline />
                            <Router />
                        </AuthenticationProvider>
                    </LocalizationProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </Provider>
    );
}

export default App;
