import { Autocomplete, Box, Card, FormHelperText, IconButton, InputAdornment, SvgIcon, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Person as PersonIcon, Business as BusinessIcon, Search as SearchIcon } from '@mui/icons-material';
import clsx from "clsx";
import { ChangeEvent, useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { UserType } from "../../../../constants/UserType";
import { FindAddressModel } from "../../../../models/system/FindAddressModel";
import { POSTAL_CODE_GB } from "../../../../constants/RegExp";
import { SystemActions } from "../../../../store/system/SystemActions";
import { getCountries } from "../../../../store/system/SystemSelectors";
import { systemService } from "../../../../services/systemService";
import { RadioCards } from "../../../../components/RadioCards";
import { FindAddressDialog } from "../../../Common/FindAddressDialog";
import { TFunction } from "i18next";
import * as Yup from 'yup';
import { makeStyles } from "@mui/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    card: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        maxWidth: theme.spacing(68),
        whiteSpace: 'nowrap',
    },
    companyCard: {
        padding: theme.spacing(3, 3, 1, 3),
    },
    cardField: {
        display: 'flex',
        width: '100%',
    },
    inputLabel: {
        marginRight: theme.spacing(3),
    },
    companyInputLabel: {
        minWidth: 154,
    },
    selectedCard: {
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
    },
    section: {
        maxWidth: theme.spacing(68),
        '&:not(:first-child)': {
            marginTop: theme.spacing(4),
        },
    },
    datePickerButton: {
        marginRight: 0,
    },
}));

interface Values {
    userType: string;
    userTypeOther: string;
    companyName: string;
    companyNumber: string;
    firstName: string;
    lastName: string;
    countryOfResidence: string;
    nationality: string;
    dateOfBirth: string;
    addressPostalCode: string;
    addressLineOne: string;
    addressLineTwo: string;
    addressCity: string;
    addressRegion: string;
    addressCountry: string;
}

export const userDetailsValidationSchema = (t: TFunction) => Yup.object().shape({
    userType: Yup.string().oneOf(['company', 'individual', 'other']).required(t('Please select user type')),
    userTypeOther: Yup.string().when('userType', { is: 'other', then: schema => schema.required(t('Please enter user type')) }),
    companyName: Yup.string().when('userType', { is: 'company', then: schema => schema.required(t('Please enter company name')) }),
    companyNumber: Yup.string().when('userType', { is: 'company', then: schema => schema.required(t('Please enter company number')) }),
    firstName: Yup.string().when('userType', { is: 'individual', then: schema => schema.required(t('Please enter your first name')) }),
    lastName: Yup.string().when('userType', { is: 'individual', then: schema => schema.required(t('Please enter your last name')) }),
    countryOfResidence: Yup.string().when('userType', { is: 'individual', then: schema => schema.required(t('Please select your country of residence')) }),
    nationality: Yup.string().when('userType', { is: 'individual', then: schema => schema.required(t('Please select your passport country')) }),
    dateOfBirth: Yup.string().when('userType', { is: 'individual', then: schema => schema.required(t('Please select your date of birth')) }),
    addressPostalCode: Yup.string().when('userType', { is: 'individual', then: schema => schema.required(t('Please enter your address postal code')) }),
    addressLineOne: Yup.string().when('userType', { is: 'individual', then: schema => schema.required(t('Please enter your address line 1')) }),
    addressLineTwo: Yup.string().when('userType', { is: 'individual', then: schema => schema.optional() }),
    addressCity: Yup.string().when('userType', { is: 'individual', then: schema => schema.required(t('Please enter your address city')) }),
    addressRegion: Yup.string().when('userType', { is: 'individual', then: schema => schema.required(t('Please enter your address region')) }),
    addressCountry: Yup.string().when('userType', { is: 'individual', then: schema => schema.required(t('Please select your address country')) }),
});

export const userDetailsInitialValues = {
    userType: '',
    userTypeOther: '',
    companyName: '',
    companyNumber: '',
    firstName: '',
    lastName: '',
    countryOfResidence: '',
    nationality: '',
    dateOfBirth: '',
    addressPostalCode: '',
    addressLineOne: '',
    addressLineTwo: '',
    addressCity: '',
    addressRegion: '',
    addressCountry: '',
};

interface UserTypeItem {
    value: UserType;
    label: string;
    icon: typeof SvgIcon;
}

export const UserDetailsStep = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    const formik = useFormikContext<Values>();

    const [findAddressDialogOpen, setFindAddressDialogOpen] = useState(false);
    const [addressesLoading, setAddressesLoading] = useState(false);
    const [foundAddresses, setFoundAddresses] = useState<FindAddressModel[]>([]);

    const isUkPostalCode = POSTAL_CODE_GB.exec(formik.values.addressPostalCode) !== null;

    const countries = useSelector(getCountries);

    useEffect(() => {
        if (countries.length === 0) {
            dispatch(SystemActions.getCountries());
        }
    }, [dispatch, countries.length]);

    const userTypes: UserTypeItem[] = [
        {
            value: UserType.Company,
            label: t('UK Registered Company'),
            icon: BusinessIcon,
        },
        {
            value: UserType.Individual,
            label: t('Individual'),
            icon: PersonIcon,
        },
    ];


    const handleUserTypeOptionChange = (value: string) => {
        void formik.setFieldValue('userType', value);
        void formik.setFieldValue('userTypeOther', '');
    };

    const handleUserTypeOtherChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (formik.values.userType !== UserType.Other) {
            void formik.setFieldValue('userType', UserType.Other);
        }
        void formik.setFieldValue('userTypeOther', e.target.value);
    };

    const handlePostCodeSearchClick = async () => {
        setAddressesLoading(true);
        setFindAddressDialogOpen(true);

        try {
            setFoundAddresses(await systemService.findAddress(formik.values.addressPostalCode));
        } catch (e) {
            console.error('Error finding address');
            setFoundAddresses([]);
        }

        setAddressesLoading(false);
    };

    const handleFindAddressSelect = (address: FindAddressModel) => {
        void formik.setFieldValue('addressLineOne', address.lineOne);
        void formik.setFieldValue('addressLineTwo', address.lineTwo);
        void formik.setFieldValue('addressCity', address.city);
        void formik.setFieldValue('addressRegion', address.region);
        setFindAddressDialogOpen(false);
    };

    return <Box>
        <Box className={classes.section}>
            <Typography gutterBottom variant="h5">
                <b>{t('How are you applying for the loan?')}</b>
            </Typography>
            <RadioCards
                selectedOption={formik.values.userType}
                options={userTypes}
                onOptionChange={handleUserTypeOptionChange}
            />
            <Card className={clsx(classes.card, { [classes.selectedCard]: formik.values.userType === UserType.Other })}>
                <Box className={classes.cardField}>
                    <Typography className={classes.inputLabel} variant="h6">
                        {t('Other')}
                    </Typography>
                    <TextField
                        fullWidth
                        name="userTypeOther"
                        value={formik.values.userTypeOther}
                        onChange={handleUserTypeOtherChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.userTypeOther && Boolean(formik.errors.userTypeOther)}
                        helperText={formik.touched.userTypeOther && formik.errors.userTypeOther}
                    />
                </Box>
            </Card>
            <FormHelperText error={formik.touched.userType && Boolean(formik.errors.userType)}>
                {formik.touched.userType && formik.errors.userType}
            </FormHelperText>
            { formik.values.userType === UserType.Company &&
                <Card className={clsx(classes.card, classes.companyCard)}>
                    <Box className={classes.cardField}>
                        <Typography className={clsx(classes.inputLabel, classes.companyInputLabel)} variant="h6">
                            {t('Company Name')}
                        </Typography>
                        <TextField
                            fullWidth
                            name="companyName"
                            value={formik.values.companyName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                            helperText={formik.touched.companyName && formik.errors.companyName || ' '}
                        />
                    </Box>
                    <Box className={classes.cardField}>
                        <Typography className={clsx(classes.inputLabel, classes.companyInputLabel)} variant="h6">
                            {t('Company Number')}
                        </Typography>
                        <TextField
                            fullWidth
                            name="companyNumber"
                            value={formik.values.companyNumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.companyNumber && Boolean(formik.errors.companyNumber)}
                            helperText={formik.touched.companyNumber && formik.errors.companyNumber || ' '}
                        />
                    </Box>
                </Card>
            }
        </Box>

        { formik.values.userType === UserType.Individual &&
            <Box className={classes.section}>
                <Typography variant="h5">
                    <b>{t('Please tell us a bit about yourself')}</b>
                </Typography>
                <TextField
                    fullWidth
                    name="firstName"
                    label={t('Your First Name')}
                    placeholder={t('Please enter your first name')}
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    helperText={formik.touched.firstName && formik.errors.firstName}
                />
                <TextField
                    fullWidth
                    name="lastName"
                    label={t('Your Last Name')}
                    placeholder={t('Please enter your last name')}
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    helperText={formik.touched.lastName && formik.errors.lastName}
                />
                <Autocomplete
                    autoHighlight
                    fullWidth
                    disableClearable
                    options={countries}
                    value={countries.find(item => item.code === formik.values.countryOfResidence) || { code: '', name: '' }}
                    isOptionEqualToValue={(option, value) => option.code === value.code}
                    getOptionLabel={option => option.name}
                    onChange={(e, option) => formik.setFieldValue('countryOfResidence', option?.code || '')}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name="countryOfResidence"
                            label={t('Country Of Residence')}
                            placeholder={t('Please select your country of residence')}
                            margin="dense"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                            error={formik.touched.countryOfResidence && Boolean(formik.errors.countryOfResidence)}
                            helperText={formik.touched.countryOfResidence && formik.errors.countryOfResidence}
                        />
                    )}
                />
                <Autocomplete
                    autoHighlight
                    fullWidth
                    disableClearable
                    options={countries}
                    value={countries.find(item => item.code === formik.values.nationality) || { code: '', name: '' }}
                    isOptionEqualToValue={(option, value) => option.code === value.code}
                    getOptionLabel={option => option.name}
                    onChange={(e, option) => formik.setFieldValue('nationality', option?.code || '')}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name="nationality"
                            label={t('Nationality')}
                            placeholder={t('Please select your nationality')}
                            margin="dense"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                            error={formik.touched.nationality && Boolean(formik.errors.nationality)}
                            helperText={formik.touched.nationality && formik.errors.nationality}
                        />
                    )}
                />
                <DatePicker
                    name="dateOfBirth"
                    label="Date Of Birth"
                    value={formik.values.dateOfBirth && moment(formik.values.dateOfBirth, 'YYYY-MM-DD') || null}
                    format="YYYY-MM-DD"
                    openTo="year"
                    maxDate={moment().subtract(18, 'years')}
                    onChange={date => formik.setFieldValue('dateOfBirth', date?.format('YYYY-MM-DD') || '')}
                    slotProps={{
                        textField: {
                            fullWidth: true,
                            placeholder: t('Please select your date of birth'),
                            onBlur: formik.handleBlur,
                            margin: 'dense',
                            error: formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth),
                            helperText: formik.touched.dateOfBirth && formik.errors.dateOfBirth,
                        },
                        openPickerButton: {
                            size: 'large',
                            className: classes.datePickerButton,
                        },
                    }}
                />
                <TextField
                    fullWidth
                    name="addressPostalCode"
                    label={t('Post Code')}
                    value={formik.values.addressPostalCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                                onClick={handlePostCodeSearchClick}
                                disabled={!isUkPostalCode}
                                title={t('Lookup for GB address')}
                                size="large"
                            >
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>,
                    }}
                    error={formik.touched.addressPostalCode && Boolean(formik.errors.addressPostalCode)}
                    helperText={formik.touched.addressPostalCode && formik.errors.addressPostalCode}
                />
                <TextField
                    fullWidth
                    name="addressLineOne"
                    label={t('Address Line 1')}
                    value={formik.values.addressLineOne}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    error={formik.touched.addressLineOne && Boolean(formik.errors.addressLineOne)}
                    helperText={formik.touched.addressLineOne && formik.errors.addressLineOne}
                />
                <TextField
                    fullWidth
                    name="addressLineTwo"
                    label={t('Address Line 2')}
                    value={formik.values.addressLineTwo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    error={formik.touched.addressLineTwo && Boolean(formik.errors.addressLineTwo)}
                    helperText={formik.touched.addressLineTwo && formik.errors.addressLineTwo}
                />
                <TextField
                    fullWidth
                    name="addressCity"
                    label={t('Town / City')}
                    value={formik.values.addressCity}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    error={formik.touched.addressCity && Boolean(formik.errors.addressCity)}
                    helperText={formik.touched.addressCity && formik.errors.addressCity}
                />
                <TextField
                    fullWidth
                    name="addressRegion"
                    label={t('County / Region')}
                    value={formik.values.addressRegion}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    error={formik.touched.addressRegion && Boolean(formik.errors.addressRegion)}
                    helperText={formik.touched.addressRegion && formik.errors.addressRegion}
                />
                <Autocomplete
                    autoHighlight
                    fullWidth
                    disableClearable
                    options={countries}
                    value={countries.find(item => item.name === formik.values.addressCountry) || { code: '', name: '' }}
                    isOptionEqualToValue={(option, value) => option.code === value.code}
                    getOptionLabel={option => option.name}
                    onChange={(e, option) => formik.setFieldValue('addressCountry', option?.name || '')}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name="addressCountry"
                            label={t('Country')}
                            placeholder={t('Please select your country')}
                            margin="dense"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                            error={formik.touched.addressCountry && Boolean(formik.errors.addressCountry)}
                            helperText={formik.touched.addressCountry && formik.errors.addressCountry}
                        />
                    )}
                />
            </Box>
        }
        <FindAddressDialog
            isOpen={findAddressDialogOpen}
            isLoading={addressesLoading}
            onSelect={handleFindAddressSelect}
            onClose={() => setFindAddressDialogOpen(false)}
            addresses={foundAddresses}
        />
    </Box>;
};
