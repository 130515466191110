import {
    Card, CardContent,
    Container,
    Divider,
    Grid,
    Skeleton,
    Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { LoanActions } from '../../store/loan/LoanActions';
import { useDispatch, useSelector } from 'react-redux';
import { DataRoomLoansStatisticsModel } from '../../models/loan/DataRoomLoansStatisticsModel';
import {
    getDataRoomLoansStatistics,
    getDataRoomLoansStatisticsLoading,
} from '../../store/loan/LoanSelectors';
import { shortenFormatPounds } from '../../utils/money';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    divider:{
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        maxWidth: '320px',
        margin: 'auto',
    },

    cardContainer: {
        marginTop: '16px',
    },

    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },

    card:{
        height:'100%',
    },
}));

export const StatisticsContainer = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const statistics: DataRoomLoansStatisticsModel|null = useSelector(getDataRoomLoansStatistics);
    const statisticsLoaning = useSelector(getDataRoomLoansStatisticsLoading);

    useEffect(() => {
        if (!statistics) {
            dispatch(LoanActions.getDataRoomLoansStatistics('active'));
        }
    }, [dispatch, statistics]);


    const amount = shortenFormatPounds(statistics?.amount || 0);
    const bridgeTotalLTV = statistics?.bridgeTotalLTV || 0;
    const developmentTotalLTV = statistics?.developmentTotalLTV || 0;

    return <Container maxWidth="lg" className={classes.container}>
        <Typography gutterBottom variant="h4" align="center">
            {t('Overview of easyMoney loan book')}
        </Typography>
        <Divider  className={classes.divider} orientation={'horizontal'} variant={'middle'} />

        <Grid item container justifyContent="center" direction="row" spacing={4}
            className={classes.cardContainer}
        >
            <Grid item md={3} sm={6} xs={12}>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <Typography variant="h6" component="h2" align="center">
                            {t('Active Loans')}
                        </Typography>
                        <Typography variant="h3" align="center">
                            {statisticsLoaning ? <Skeleton /> : statistics?.count}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <Typography variant="h6" component="h2" align="center">
                            {t('Loan Book Size')}
                        </Typography>
                        <Typography variant="h3" align="center">
                            {statisticsLoaning ? <Skeleton /> : amount}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <Typography variant="h6" component="h2" align="center">
                            {t('Average LTV (Bridge)')}
                        </Typography>
                        <Typography variant="h3" align="center">
                            {statisticsLoaning ? <Skeleton /> : `${bridgeTotalLTV}%`}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <Typography variant="h6" component="h2" align="center">
                            {t('Average LTGDV (Development)')}
                        </Typography>
                        <Typography variant="h3" align="center">
                            {statisticsLoaning ? <Skeleton /> : `${developmentTotalLTV}%`}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </Container>;
};
