import { Card, CardActionArea, CardContent, Grid, SvgIcon, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    grid: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            width: theme.spacing(32),
            height: theme.spacing(32),
        },
    },
    cardContent: {
        width: theme.spacing(32),
        height: theme.spacing(32),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    icon: {
        fontSize: '6rem',
    },
    selectedType: {
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
    },
}));

interface RadioCardOption {
    value: string;
    label: string;
    icon: typeof SvgIcon;
}

interface RadioCardProps {
    options: RadioCardOption[];
    selectedOption?: RadioCardOption['value'];
    onOptionChange: (value: RadioCardOption['value']) => void;
}

export const RadioCards = (props: RadioCardProps) => {
    const { options, selectedOption, onOptionChange } = props;
    const classes = useStyles();

    return <Grid className={classes.grid} container direction="row" justifyContent="flex-start" alignItems="flex-start" gap={4}>
        {options.map((item, index) => (
            <Card key={index}>
                <CardActionArea disableRipple onClick={() => onOptionChange(item.value)}>
                    <CardContent className={clsx(classes.cardContent, { [classes.selectedType]: selectedOption === item.value })}>
                        <Typography variant="h6">{item.label}</Typography>
                        <item.icon className={classes.icon} />
                    </CardContent>
                </CardActionArea>
            </Card>
        ))}
    </Grid>;
};
