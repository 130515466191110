import {
    Table as MaterialUITable,
    TableBody,
    TableRow,
    TableCell,
    TableFooter,
    TablePagination,
    IconButton,
    Skeleton,
} from '@mui/material';
import {
    LastPage,
    FirstPage,
    KeyboardArrowRight,
    KeyboardArrowLeft,
} from '@mui/icons-material';
import { useCallback, useState } from 'react';
import * as React from 'react';
import {
    ColumnDefinitionType,
    SortableHeader,
    SortConfig, SortingType,
} from './SortableHeader';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    spacer: {
        flex: '1 1 100%',
        order: 1,
    },

    selectRoot: {
        marginRight: 32,
        marginLeft: 0,
    },

    toolbar: {
        paddingLeft: 16,
    },

    pagination: {
        '& p:last-of-type': {
            order: 2,
        },
    },

    root: {
        flexShrink: 0,
        order: 3,
    },
}));

type TableProps<T> = {
    data: T[];
    columns: ColumnDefinitionType<keyof T>[];
    dataLoading: boolean;
    sortHandler?: {
        onChange: (page: number, pageSize: number, sortColumn: string|number|symbol,
            order: string) => void;
    };
    pagination?: PaginationConfig;
};

interface PaginationConfig {
    total: number;
    page: number;
    rowsPerPage: number;
    handleChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => void;
    handleChangeRowsPerPage: (event: { target: { value: string } }) => void;
}

interface TablePaginationActionsProps {
    count: number;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
    page: number;
    rowsPerPage: number;
}

const placeholders = Array(10).fill(true);

function TablePaginationActions(props: TablePaginationActionsProps) {
    const classes = useStyles();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
                size="large"
            >
                <FirstPage />
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
                size="large"
            >
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
                size="large"
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
                size="large"
            >
                <LastPage />
            </IconButton>
        </div>
    );
}

const PAGE_SIZE = 50;

export const Table = <T extends Record<string | number, React.ReactNode>> (props: TableProps<T>) => {
    const { data, columns, dataLoading, sortHandler, pagination } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    const [sortConfig, updateSortConfig] = useState<SortConfig<keyof T>>(
        {} as SortConfig<keyof T>,
    );

    const paginationPage = pagination?.page || 0;
    const paginationRowsPerPage = pagination?.rowsPerPage || PAGE_SIZE;

    const sortBy = useCallback((propertyName: keyof T) => {
        let pendingChange = sortConfig;
        if (pendingChange.propertyName === propertyName) {
            // Existing configuration
            //Save the sortType
            const currentSortType = pendingChange.sortType;
            //Remove existing config
            pendingChange = {} as SortConfig<keyof T>;
            //check if the sort type we saved is descending
            if (currentSortType === SortingType.Descending) {
                pendingChange = {
                    propertyName: propertyName,
                    sortType: SortingType.Ascending,
                };
            }
        } else {
            // No existing configuration
            pendingChange = {
                propertyName: propertyName,
                sortType: SortingType.Descending,
            };
        }

        updateSortConfig(pendingChange);
        sortHandler?.onChange(
            paginationPage + 1,
            paginationRowsPerPage,
            pendingChange.propertyName,
            pendingChange.sortType,
        );

    }, [sortConfig, paginationPage, paginationRowsPerPage, sortHandler]);

    return <MaterialUITable>
        <SortableHeader
            columns={columns}
            sortConfig={sortConfig}
            sortBy={sortBy}
        />
        {dataLoading ?
            <TableBody>
                {
                    placeholders.map((row, index) =>
                        (<TableRow key={`row-${index}`}>
                            {columns.map((column, index2) => (
                                <TableCell
                                    key={`cell-${index2}`}
                                ><Skeleton /></TableCell>
                            ))}
                        </TableRow>
                        ))
                }
            </TableBody> :
            <TableBody>
                {
                    data.length > 0 ?
                        data.map((row, index) =>
                            (<TableRow key={`row-${index}`}>
                                {columns.map((column, index2) => (
                                    <TableCell
                                        key={`cell-${index2}`}
                                    >{row[column.key]}</TableCell>
                                ))}
                            </TableRow>
                            )):
                        <TableRow>
                            <TableCell
                                align={'center'}
                                colSpan={columns.length}
                            >
                                {t('No records found')}
                            </TableCell>
                        </TableRow>
                }
            </TableBody>
        }
        {pagination && data.length > 0 && <TableFooter>
            <TableRow>
                <TablePagination
                    className={classes.pagination}
                    rowsPerPageOptions={[25, 50, 75, 100]}
                    colSpan={columns.length}
                    count={pagination.total}
                    rowsPerPage={pagination.rowsPerPage}
                    page={pagination.page}
                    SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                    }}
                    onPageChange={pagination.handleChangePage}
                    onRowsPerPageChange={pagination.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    classes={{
                        spacer: classes.spacer,
                        selectRoot: classes.selectRoot,
                        toolbar: classes.toolbar,
                    }
                    }
                />
            </TableRow>
        </TableFooter>}
    </MaterialUITable>;
};
