import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Moment } from "moment";
import { useTranslation } from "react-i18next";
import { formatPounds } from "../../../../utils/money";
import { makeStyles } from "@mui/styles";

export interface Payment {
    dueDate: Moment;
    loanCode: string;
    description: string;
    amount: number;
}

interface OverduePaymentsProps {
    payments: Payment[];
    className?: string;
}

const useStyles = makeStyles((theme) => ({
    tableHead: {
        background: theme.palette.grey[100],
    },
}));

export const PaymentsTable = ({ payments, className }: OverduePaymentsProps) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return <TableContainer className={className}>
        <Table>
            <TableHead className={classes.tableHead}>
                <TableRow>
                    <TableCell><b>{t('Date')}</b></TableCell>
                    <TableCell><b>{t('Drawdown')}</b></TableCell>
                    <TableCell><b>{t('Description')}</b></TableCell>
                    <TableCell><b>{t('Amount')}</b></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {payments.map((row, index) => (
                    <TableRow key={index}>
                        <TableCell>{row.dueDate.format('DD/MM/YYYY')}</TableCell>
                        <TableCell>{row.loanCode}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>{formatPounds(row.amount)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>;
};
